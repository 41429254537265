import { Routes, Route } from "react-router-dom"

import { NotFound } from "../Pages/404";
import { AffectedServices } from "../Pages/AffectedServices";
import { BackofficeCompany } from "../Pages/Backoffice/Company";
import { BackofficeLocation } from "../Pages/Backoffice/Location";
import { BackofficeComponent } from "../Pages/Backoffice/Component";
import { IncidentManagement } from "../Pages/IncidentManagement";
import { BackofficeService } from "../Pages/Backoffice/Services";
import { BackofficeApplication } from "../Pages/Backoffice/Application";
import { BackofficeServiceApplication } from "../Pages/Backoffice/ServiceApplications";

export function AppRoutes() {
	return (
		<Routes>
			<Route path="/" element={<AffectedServices />} />
			<Route path="/manage/incident" element={<IncidentManagement />} />
			<Route path="/manage/locations" element={<BackofficeLocation />} />
			<Route path="/manage/companies" element={<BackofficeCompany />} />
			<Route path="/manage/components" element={<BackofficeComponent />} />
			<Route path="/manage/services" element={<BackofficeService />} />
			<Route path="/manage/applications" element={<BackofficeApplication />} />
			<Route path="/manage/applicationServices" element={<BackofficeServiceApplication />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}