import React, { 
    createContext, 
    useContext, 
    useEffect, 
    useState 
} from "react";
import jwtDecode from "jwt-decode";
import { useMsal } from "@azure/msal-react";
import { useToast } from "@chakra-ui/react";

import { IAuthContext, IDecodedJwtToken } from "./interface";
import { loginRequest } from "../../Utils/authConfig";
import {api} from "../../Api/index"

const AuthContext = createContext({} as IAuthContext)

const AuthProvider: React.FC<any> = ({ children }) => {
    const toast = useToast()  
    const { instance, accounts } = useMsal()
    const [tokenExpireDate, setTokenExpireDate] = useState<Date | null>(null)
    const [accessToken, setAccessToken] = useState('')
    const [userRoles, setUserRoles] = useState<string[] | undefined>([])


    api.interceptors.request.use((config) => {
        if(tokenExpireDate && tokenExpireDate < new Date()){
           return getTokenSilently().then(token => {
                if(config.headers){
                    config.headers.Authorization = "Bearer " + token;
                }
                return Promise.resolve(config);
            });            
        }else{
            return Promise.resolve(config);
        }
    })

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).then().catch(() => {
        })
    }

    const handleLogout = () => {
        if(!accounts || accounts?.length == 0){
            instance.logoutRedirect().catch(() => {
                toast({
                    title: 'LogOut!',
                    description: "Ocorreu erro tentar fazer logout da sua conta!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
        }else if(accounts.length > 1){
            toast({
                title: 'LogOut!',
                description: "Várias contas foram detetadas.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }else{
            const logoutRequest = {
                account: instance.getAccountByUsername(accounts[0].username),
                postLogoutRedirectUri: process.env.REACT_APP_MSAL_CONFIG_REDIRECT_URI as string
            };
            instance.logoutRedirect(logoutRequest).catch(() => {
                toast({
                    title: 'LogOut!',
                    description: "Ocorreu erro tentar fazer logout da sua conta!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
        }
    }

    const getTokenSilently = () => {
        return new Promise<string>((resolve, reject) => {
            const request = {
                ...loginRequest,
                account: accounts[0]
            }
    
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request).then((response) => {
                const decodedToken = jwtDecode<IDecodedJwtToken>(response.accessToken)
                setUserRoles(decodedToken.roles)
                setAccessToken(response.accessToken)
                if(response.expiresOn){
                    setTokenExpireDate(response.expiresOn)
                }
                resolve(response.accessToken)
            }).catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    setAccessToken(response.accessToken)
                    if(response.expiresOn){
                        setTokenExpireDate(response.expiresOn)
                    }
                    resolve(response.accessToken)
                });
            });
        })
    }

    useEffect(() => {

        const getTokenSilentlyHook = () => {
            const request = {
                ...loginRequest,
                account: accounts[0]
            }
    
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request).then((response) => {
                const decodedToken = jwtDecode<IDecodedJwtToken>(response.accessToken)
                setUserRoles(decodedToken.roles)
                setAccessToken(response.accessToken)
                if(response.expiresOn){
                    setTokenExpireDate(response.expiresOn)
                }
            }).catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    setAccessToken(response.accessToken)
                    if(response.expiresOn){
                        setTokenExpireDate(response.expiresOn)
                    }
                });
            });
        }

        if(accounts.length) {
            getTokenSilentlyHook()
        }        

        // eslint-disable-next-line
    }, [accounts])

    return(
        <AuthContext.Provider
            value={{
                handleLogin,
                handleLogout,
                accessToken,
                userRoles
            }}
        >
            { children }
        </AuthContext.Provider>
    )
}


const useAuth = () => {
    const authContext = useContext(AuthContext)

    return authContext
}

export { useAuth, AuthProvider }