import { 
    TableContainer, 
    Table, 
    Thead, 
    Tr, 
    Th, 
    Tbody, 
    Td, 
    Box, 
    Text, 
    Divider, 
    Skeleton,  
    Flex,
    Badge,
    FormControl,
    FormLabel
} from "@chakra-ui/react";
import Select from 'react-select'

import { IMyTableProps } from "./interface";
import './style.css'

export function MyTable({columns, data, loading, handleTableRowClick}: IMyTableProps) {

    const dataIndexs = columns.map(col => col.dataIndex)    
    const { data: tableRecords, pagination } = data
    
    return (
            <TableContainer overflowX="auto" overflowY="auto" maxHeight="60vh">
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            {columns.map(column => (
                                <Th key={column.key}>{column.title}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {loading && (
                            [1, 2].map(row => (
                                <Tr key={row}>
                                    {dataIndexs.map(dataIndex => (
                                        <Td key={dataIndex}>
                                            <Skeleton height='10px' />
                                        </Td>
                                    ))}
                                </Tr>
                            ))
                        )}
                        {tableRecords?.map(row => (
                            <Tr key={row.id} className="tableRow" onClick={() => handleTableRowClick(row.id)}>
                                {dataIndexs.map(dataIndex => {
                                    const foundedColumn = columns.find(column => column.dataIndex === dataIndex)
                                    if(foundedColumn?.render) {
                                        return(
                                            <Td key={`${row.key}-${dataIndex}`}>{foundedColumn.render(row, dataIndex)}</Td>
                                        )
                                    }

                                    return(
                                        <Td key={`${row.key}-${dataIndex}`}>{row?.[dataIndex] ?? ""}</Td>
                                    )
                                })}                                
                            </Tr>
                        ))}
                    </Tbody>
                </Table >
            </TableContainer>
    )
}