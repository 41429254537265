import { Button, HStack, IconButton } from "@chakra-ui/react"
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import { IPaginationProps } from "./interface";
import { DOTS, usePagination } from "../../Hook/usePagination";


export function Pagination(props: IPaginationProps) {

    const {
        currentPage,
        pageSize,
        totalCount,
        siblingCount,
        onPageChange,
        totalPageCount,
        hasNextPage,
        hasPreviousPage
    } = props

    const paginationRange = usePagination({
        currentPage,
        pageSize,
        totalCount,
        siblingCount,
        totalPageCount
    }) || []

    if(currentPage === 0 || paginationRange?.length < 2) {
        return null
    }

    const onNext = () => onPageChange(currentPage + 1)
    const onPrevious = () => onPageChange(currentPage - 1)

    // let lastPage = paginationRange[paginationRange.length - 1]

    return (
        <HStack mt="4" spacing={4}>
            <IconButton disabled={!hasPreviousPage} icon={<FiChevronLeft />} aria-label='Search database' variant="outline" borderColor="blackAlpha.300" color="black" onClick={onPrevious} />

            {paginationRange.map((pageNumber, index) => {

                if(pageNumber === DOTS) {
                    return <Button key={`${pageNumber}-${index}`} variant="outline" borderColor="blackAlpha.300" color="black">&#8230;</Button>
                }

                return (
                    <Button key={`${pageNumber}-${index}`} variant="outline" borderColor="blackAlpha.300" backgroundColor={pageNumber === currentPage ? "black" : "white"} color={pageNumber === currentPage ? "white" : "black"} onClick={() => onPageChange(pageNumber as number)}>
                        {pageNumber}
                    </Button>
                )
            })}

            <IconButton disabled={!hasNextPage} icon={<FiChevronRight />} aria-label='Search database' variant="outline" borderColor="blackAlpha.300" color="black" onClick={onNext} />
        </HStack>
    )
}