/**
* Configuration object to be passed to MSAL instance on creation.
* For a full list of MSAL.js configuration parameters, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
*/
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CONFIG_CLIENT_ID as string,
        authority: process.env.REACT_APP_MSAL_CONFIG_AUTHORITY as string,
        redirectUri: process.env.REACT_APP_MSAL_CONFIG_REDIRECT_URI as string
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

export const loginRequest  = {
    scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPES as string]
};