import { useEffect, useState } from "react";
import { Container, Flex, Box, TableContainer, Table, Thead, Tbody, Th, Tr, Td, Skeleton } from "@chakra-ui/react";
import { ServiceApplicationForm } from "./Form";
import { Pagination } from "../../../Components/Pagination";
import { Navigation } from "../../../Components/Navigation";
import { IPaginationRequest } from "../../../Api/IncidentManagement/interface";
import { IMyTableColumn, IResponse } from "../../../Components/Table/interface";
import { getServiceApplicationsWithPagination } from "../../../Api/IncidentManagement";
import { useAuth } from "../../../Hook/useAuth";
import { Menu } from "../../../Components/Menu";
import { MENU_KEYS } from "../../../Api/Constants";
import { Header } from "../../../Components/Header/Header";

export function BackofficeServiceApplication() {
    const { accessToken } = useAuth()
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(true);    
    const [reloadTable, setReloadTable] = useState(false);    
    const [serviceApplicationId, setServiceApplicationToEdit] = useState({serviceId: 0, applicationId: 0}); 
    const [data, setData] = useState<IResponse>({} as IResponse)
    const [showServiceApplicationForm, setShowServiceApplicationForm] = useState(false);

    const currentMenuKey = MENU_KEYS.ApplicationServiceMenuKey;

    const tableColumns: IMyTableColumn[] = [
        {
            key: "applicationName",
            title: "Produto",
            dataIndex: "applicationName",
        },
        {
            key: "serviceName",
            title: "Sistema",
            dataIndex: "serviceName", 
        }
    ]

    const handleServiceApplicationForm = () => {
        setShowServiceApplicationForm(!showServiceApplicationForm)

        if(serviceApplicationId) {
            handleTableRowClick(0, 0)
        }
    }

    const handleTableRowClick = (serviceId: any, applicationId: any) => {
        setServiceApplicationToEdit({serviceId: serviceId, applicationId: applicationId})
        setShowServiceApplicationForm(!showServiceApplicationForm)
    }

    const handleReloadTable = () => {
        setReloadTable(!reloadTable)
        setLoading(true)
        handleServiceApplicationForm()

        if(serviceApplicationId?.serviceId && serviceApplicationId?.applicationId) {
            handleTableRowClick(0, 0)
        }
    }

    useEffect(()=>  {
        const loadServices = async ({ page, pageSize = 10 }: IPaginationRequest) => {
            try {
                if(!loading) {
                    setLoading(true)
                } 

                const response = await getServiceApplicationsWithPagination({page, pageSize}, accessToken)
                if(response.status === 200) {
                    setData(response.data)
                    setCurrentPage(response.data?.pagination?.currentPageNumber)
                }
            } catch (error) {
            } finally {
                setLoading(false)
            }
        }
        if(accessToken){
            loadServices({page: currentPage, pageSize: 10});
        }
    }, [reloadTable, currentPage, accessToken])

    const dataIndexs = tableColumns.map(col => col.dataIndex)    
    const { data: tableRecords, pagination } = data

    return(
        <Container maxW='container.xl' p={8}>
            <Navigation handleAddAction={handleServiceApplicationForm} addButtonText="Adicionar Relação" showSettingsButton={false} />
            <Flex>
                <Menu activeKey={currentMenuKey}></Menu>
                
                <Box bg='' w="100%" 
                    borderRightWidth='1px'
                    borderTopWidth='1px'
                    borderBottomWidth='1px'
                    borderTopRightRadius='md'
                    borderBottomRightRadius='md'>
                    <Header title="Adicionar ou remover produtos e sistemas"/>

                    <TableContainer overflowX="auto" overflowY="auto" maxHeight="70vh">
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    {tableColumns.map(column => (
                                        <Th key={column.key}>{column.title}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loading && (
                                    [1, 2].map(row => (
                                        <Tr key={row}>
                                            {dataIndexs.map(dataIndex => (
                                                <Td key={dataIndex}>
                                                    <Skeleton height='10px' />
                                                </Td>
                                            ))}
                                        </Tr>
                                    ))
                                )}
                                {tableRecords?.map(row => (
                                    <Tr key={row.id} className="tableRow" onClick={() => handleTableRowClick(row?.["serviceId"], row?.["applicationId"])}>
                                        {dataIndexs.map(dataIndex => {
                                            const foundedColumn = tableColumns.find(column => column.dataIndex === dataIndex)
                                            if(foundedColumn?.render) {
                                                return(
                                                    <Td key={`${row.key}-${dataIndex}`}>{foundedColumn.render(row, dataIndex)}</Td>
                                                )
                                            }

                                            return(
                                                <Td key={`${row.key}-${dataIndex}`}>{row?.[dataIndex] ?? ""}</Td>
                                            )
                                        })}                                
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table >
                    </TableContainer>

                    <Box marginLeft={4}>
                        <Pagination 
                            onPageChange={page => setCurrentPage(page)} 
                            currentPage={currentPage} 
                            totalCount={data?.pagination?.pageRecordNumber} 
                            pageSize={data?.pagination?.totalRecords}
                            totalPageCount={data?.pagination?.totalPages}
                            hasNextPage={data?.pagination?.hasNextPage}
                            hasPreviousPage={data?.pagination?.hasPreviousPage}
                        /> 
                    </Box>
                </Box>

                <ServiceApplicationForm 
                    isOpen={showServiceApplicationForm} 
                    setIsOpen={handleServiceApplicationForm} 
                    handleReloadTable={handleReloadTable} 
                    serviceId={serviceApplicationId.serviceId}
                    applicationId={serviceApplicationId.applicationId} 
                />
            </Flex>
        </Container>
    )
}