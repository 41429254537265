import { Routes, Route } from "react-router-dom"

import { NotFound } from "../Pages/404";
import { SignIn } from "../Pages/SignIn";

export function AuthRoutes() {
	return (
		<Routes>
			<Route path="/" element={<SignIn />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}