import { useEffect, useState } from "react";
import { format } from 'date-fns'
import { Badge, Container, Text, Tooltip, useToast, Grid, GridItem, Box } from "@chakra-ui/react";

import { IncidentForm } from "./IncidentForm";
import { MyTable } from "../../Components/Table";
import { truncateString } from "../../Utils/shared";
import { ICommonProps } from "./IncidentForm/interface";
import { Pagination } from "../../Components/Pagination";
import { Navigation } from "../../Components/Navigation";
import { IRequestPagination } from "../../Api/IncidentManagement/interface";
import { IMyTableColumn, IResponse } from "../../Components/Table/interface";
import { getIncident, getIncidentStatus } from "../../Api/IncidentManagement";
import { useAuth } from "../../Hook/useAuth";
import { Menu } from "../../Components/Menu";
import { Header } from "../../Components/Header/Header";

export function IncidentManagement() {
    const toast = useToast()  
    const { accessToken } = useAuth()
    const [loading, setLoading] = useState(true);    
    const [currentPage, setCurrentPage] = useState(1)
    const [reloadTable, setReloadTable] = useState(false);    
    const [incidentToEdit, setIncidentToEdit] = useState(0); 
    const [data, setData] = useState<IResponse>({} as IResponse)
    const [showIncidentForm, setShowIncidentForm] = useState(false);
    const [statusData, setStatusData] = useState<ICommonProps[]>([]); 
    const [statusToFilterBy, setStatusToFilterBy] = useState<any>(undefined); 

    const tableColumns: IMyTableColumn[] = [
        {
            key: "service",
            title: "Nome do serviço",
            dataIndex: "service",
            render: (row, dataIndex) => (
                <Tooltip label={row?.[dataIndex]}>
                    <Text>{truncateString(row?.[dataIndex], 20)}</Text>
                </Tooltip>
            )
        },
        {
            key: "createdAt",
            title: "Data de incidente",
            dataIndex: "createdAt",
            render: (row, dataIndex) => format(new Date(row?.[dataIndex] || null), 'dd/MM/yyyy') 
        },
        {
            key: "expectedResolutionDate",
            title: "Data expectável de resolução",
            dataIndex: "expectedResolutionDate",
            render: (row, dataIndex) => format(new Date(row?.[dataIndex] || null), 'dd/MM/yyyy') 
        },
        {
            key: "impactLevel",
            title: "Nível de impacto",
            dataIndex: "impactLevel",
            render: (row, dataIndex) => {
                switch (row?.[dataIndex]) {
                    case "Degradação de serviço":
                        return (
                            <Badge colorScheme="orange" px="4" py="1" borderRadius="12">
                                <Text color="orange">
                                    {row?.[dataIndex]}
                                </Text>                    
                            </Badge>
                        )
                    case "Indisponibilidade total":
                        return (
                            <Badge colorScheme="red" px="4" py="1" borderRadius="12">
                                <Text color="red">
                                    {row?.[dataIndex]}
                                </Text>                    
                            </Badge>
                        )
                    default:
                        return row?.[dataIndex]
                }
            }
        },
        {
            key: "reason",
            title: "Motivo",
            dataIndex: "reason",
            render: (row, dataIndex) => (
                <Tooltip label={row?.[dataIndex]}>
                    <Text>{truncateString(row?.[dataIndex], 20)}</Text>
                </Tooltip>
            )
        },
    ]

    const handleShowIncidentForm = () => {
        setShowIncidentForm(!showIncidentForm)

        if(incidentToEdit) {
            handleTableRowClick(0)
        }
    }

    const handleTableRowClick = (row: any) => {
        setIncidentToEdit(row)
        setShowIncidentForm(!showIncidentForm)
    }

    const handleReloadTable = () => {
        setReloadTable(!reloadTable)
        setLoading(true)
        handleShowIncidentForm()

        if(incidentToEdit) {
            handleTableRowClick(0)
        }
    }

    

    useEffect(() => {

        const loadIncidentStatus = async(token: string) => {
            try {
                const response = await getIncidentStatus(token)
    
                if(response.status === 200) {
                    const options= response.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                    setStatusData(options)
                    setStatusToFilterBy(options[0])
                } else {
                    toast({
                        title: 'Incidente Estado',
                        description: "Ocorreu carregar estados",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Incidente Estado',
                    description: "Ocorreu carregar estados",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }

        if(accessToken) {
            loadIncidentStatus(accessToken)
        }       

        // eslint-disable-next-line
    }, [accessToken])

    useEffect(()=>  {

        const loadIncident = async ({ page, pageSize = 10, statusId }: IRequestPagination) => {
            try {
                if(!loading) {
                    setLoading(true)
                } 
                
                const response = await getIncident({page, pageSize, statusId}, accessToken)
                if(response.status === 200) {
                    setData(response.data)
                    setCurrentPage(response.data?.pagination?.currentPageNumber)
                }
            } catch (error) {
            } finally {
                setLoading(false)
            }
        }

        if(statusToFilterBy?.value) {
            loadIncident({page: currentPage, pageSize: 10, statusId: statusToFilterBy.value})
        }

        // eslint-disable-next-line
    }, [reloadTable, currentPage, statusToFilterBy])

    return(
        <Container maxW='container.xl' p={8}>
            <Navigation handleAddAction={handleShowIncidentForm} addButtonText="Adicionar Incidência" showSettingsButton={true} />

            <Box bg='' w="100%" borderWidth='1px' borderRadius='md'>
                <Header
                    title="Incidências"
                    showFilter={true}
                    recordCounter={data?.pagination?.totalRecords || undefined}
                    filterItems={statusData}
                    filterValue={statusToFilterBy}
                    onFilterChange={setStatusToFilterBy}/>

                <MyTable  
                    data={data} 
                    loading={loading} 
                    columns={tableColumns} 
                    handleTableRowClick={handleTableRowClick}
                />
            </Box>

            <Pagination 
                onPageChange={page => setCurrentPage(page)} 
                currentPage={currentPage} 
                totalCount={data?.pagination?.pageRecordNumber} 
                pageSize={data?.pagination?.totalRecords}
                totalPageCount={data?.pagination?.totalPages}
                hasNextPage={data?.pagination?.hasNextPage}
                hasPreviousPage={data?.pagination?.hasPreviousPage}
            /> 

            <IncidentForm 
                statusData={statusData} 
                isOpen={showIncidentForm} 
                setIsOpen={handleShowIncidentForm} 
                handleReloadTable={handleReloadTable} 
                incidentToEdit={incidentToEdit} 
            />
            
        </Container>
    )
}