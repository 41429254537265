import { FiLogIn } from 'react-icons/fi'
import { Box, Button, Container, Image, Text, VStack } from "@chakra-ui/react"

import { useAuth } from '../../Hook/useAuth'
import Logo from "../../Assets/Img/AMORIM.png"

export function SignIn() {

    const { handleLogin } = useAuth();
    handleLogin();
    return(
        <Container height="100vh" maxW='container.xl' p={8}>
           
        </Container>
    )
}