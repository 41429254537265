import { Button, Container, Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export const NotFound = () => {
    const navigate = useNavigate()    

    useEffect(() => {
        navigate("/")
        // eslint-disable-next-line
    }, [])
    
    return (
        <Container centerContent>
            <Flex direction="column" height="100vh" justifyContent="center" alignItems="center">
                <Text>Page Not Found</Text>
                <Button>
                    <Link to="/">
                        Back
                    </Link>
                </Button>
            </Flex>
        </Container>
    )
}