import React from "react";
import { 
    Button, 
    Drawer, 
    DrawerBody, 
    DrawerCloseButton, 
    DrawerContent, 
    DrawerFooter, 
    DrawerHeader, 
    DrawerOverlay, 
    FormControl, 
    FormLabel, 
    Divider,
    Stack, 
    Box,
    Flex,
    Badge,
    Text, 
    Textarea,
    useToast, 
} from "@chakra-ui/react";
import Select from 'react-select'
import { IHeaderProps } from "./interface";
import { ICommonProps } from "../../Pages/IncidentManagement/IncidentForm/interface";

export function Header({showFilter, title, recordCounter, onFilterChange, filterItems, filterLabel, filterValue} : IHeaderProps){

    const handleOnFilterChange = (value: ICommonProps) => {
        if(onFilterChange){
            onFilterChange(value);
        }
    }

    const recordCounterRender = recordCounter ? 
        (<Box ml="4">
            <Badge colorScheme="blue" px="4" py="2" borderRadius="12">
                <Text color="blue" fontSize="16px">
                    {recordCounter}
                </Text>                    
            </Badge>
        </Box>) 
        : null;

    const filterRender = (showFilter || false) === true && filterItems && filterItems?.length > 0 ?
        (<Box>
            <FormControl display="flex" flexDirection="row" alignItems="center">
                <FormLabel>{filterLabel || ''}</FormLabel>
                <Select
                    onChange={value => handleOnFilterChange(value)}
                    options={filterItems}
                    value={filterValue}
                />
            </FormControl>                            
        </Box>)
        : null;


    return (
            <Box>
                <Flex p="5" alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                        <Text fontWeight="600" fontSize="20">{title || ''}</Text>
                        { recordCounterRender } 
                    </Flex>
                    {filterRender}  
                </Flex>
                <Divider />
            </Box>
        )
}
