import { api } from "..";
import { ICreateCompany, IUpdateCompanyParam } from "../../Pages/Backoffice/Company/interface";
import { ICreateComponent, IUpdateComponentParam } from "../../Pages/Backoffice/Component/interface";
import { ICreateLocation, IUpdateLocation, IUpdateLocationParam } from "../../Pages/Backoffice/Location/interface";
import { ICreateServiceApplication } from "../../Pages/Backoffice/ServiceApplications/interface";
import { ICreateService, IUpdateServiceParam } from "../../Pages/Backoffice/Services/interface";
import { endpoints } from "../Constants";
import { cancelTokenSource } from './../index';
import { ICreateIncident, IPaginationRequest, IRequestPagination, IUpdateIncidentRequestParam } from "./interface";

// GET DATA TO FILL CREATE INCIDENT COMBOBOX'S
export const getCompanys = (token: string) => api.get(`${endpoints.COMPANY}/GetCompanyCodes`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getLocations = (token: string) => api.get(endpoints.LOCATION, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getIncidentStatus = (token: string) => api.get(`${endpoints.INCIDENT_STATUS}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getIncidentImpactLevels = (token: string) => api.get(endpoints.INCIDENT_IMPACT_LEVEL, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getComponetsByLocation = (locationId: number, token: string) => api.get(`${endpoints.COMPONENT_BY_LOCATION}/${locationId}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getServicesByComponent = (componentId: number, token: string) => api.get(`${endpoints.SERVICE_BY_COMPONENT}/${componentId}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getApplicationsByService = (serviceId: number, token: string) => api.get(`${endpoints.APPLICATION_BY_SERVICE}/${serviceId}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })

// MANAGE INCIDENT
export const getIncident = ({page, pageSize, statusId}: IRequestPagination, token: string) => api.get(`${endpoints.INCIDENT}?page=${page}&pageSize=${pageSize}&statusId=${statusId}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const postIncident = (data: ICreateIncident, token: string) => api.post(endpoints.INCIDENT, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const deleteIncident = (incidentId: number, token: string) => api.delete(`${endpoints.INCIDENT}/${incidentId}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getIncidentById = (incidentId: number, token: string, getForEdit: boolean) => api.get(`${endpoints.INCIDENT}/${incidentId}?getForEdit=${getForEdit}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const updateIncident = ({incidentId, data}: IUpdateIncidentRequestParam, token: string) => api.patch(`${endpoints.INCIDENT}/${incidentId}`, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })

// BACKOFFICE
export const getLocationsWithPagination = ({page, pageSize}: IPaginationRequest, token: string) => api.get(`${endpoints.LOCATION}?page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const postLocation = (data: ICreateLocation, token: string) => api.post(endpoints.LOCATION, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const updateLocation = ({id, data}: IUpdateLocationParam, token: string) => api.patch(`${endpoints.LOCATION}/${id}`, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const deleteLocation = (id: number, token: string) => api.delete(`${endpoints.LOCATION}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getLocationById = (id: number, token: string) => api.get(`${endpoints.LOCATION}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })

export const getCompaniesWithPagination = ({page, pageSize}: IPaginationRequest, token: string) => api.get(`${endpoints.COMPANY}?page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const postCompany = (data: ICreateCompany, token: string) => api.post(endpoints.COMPANY, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const updateCompany = ({id, data}: IUpdateCompanyParam, token: string) => api.patch(`${endpoints.COMPANY}/${id}`, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const deleteCompany = (id: number, token: string) => api.delete(`${endpoints.COMPANY}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getCompanyById = (id: number, token: string) => api.get(`${endpoints.COMPANY}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })

export const getComponentsWithPagination = ({page, pageSize}: IPaginationRequest, token: string) => api.get(`${endpoints.COMPONENT}?page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getComponents = (token: string) => api.get(`${endpoints.COMPONENT}/GetAllComponents`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const postComponent = (data: ICreateComponent, token: string) => api.post(endpoints.COMPONENT, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const updateComponent = ({id, data}: IUpdateComponentParam, token: string) => api.patch(`${endpoints.COMPONENT}/${id}`, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const deleteComponent = (id: number, token: string) => api.delete(`${endpoints.COMPONENT}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getComponentById = (id: number, token: string) => api.get(`${endpoints.COMPONENT}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })

export const getServicesWithPagination = ({page, pageSize}: IPaginationRequest, token: string) => api.get(`${endpoints.SERVICE}?page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const postService = (data: FormData, token: string) => api.post(endpoints.SERVICE, data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }, cancelToken: cancelTokenSource.token })
export const updateService = ({id, data}: IUpdateServiceParam, token: string) => api.patch(`${endpoints.SERVICE}/${id}`, data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }, cancelToken: cancelTokenSource.token })
export const deleteService = (id: number, token: string) => api.delete(`${endpoints.SERVICE}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getServiceById = (id: number, token: string) => api.get(`${endpoints.SERVICE}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })

export const getApplicationsWithPagination = ({page, pageSize}: IPaginationRequest, token: string) => api.get(`${endpoints.APPLICATION}?page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const postApplication = (data: FormData, token: string) => api.post(endpoints.APPLICATION, data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }, cancelToken: cancelTokenSource.token })
export const updateApplication = ({id, data}: IUpdateServiceParam, token: string) => api.patch(`${endpoints.APPLICATION}/${id}`, data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }, cancelToken: cancelTokenSource.token })
export const deleteApplication = (id: number, token: string) => api.delete(`${endpoints.APPLICATION}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getApplicationById = (id: number, token: string) => api.get(`${endpoints.APPLICATION}/${id}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })

export const getServices = (token: string) => api.get(`${endpoints.SERVICE}/GetAllServices`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getApplications = (token: string) => api.get(`${endpoints.APPLICATION}/GetAllApplications`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const getServiceApplicationsWithPagination = ({page, pageSize}: IPaginationRequest, token: string) => api.get(`${endpoints.SERVICE}/Application?page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const postServiceApplication = (data: ICreateServiceApplication, token: string) => api.post(`${endpoints.SERVICE}/Application`, data, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })
export const deleteServiceApplication = (serviceId: number, applicationId: number, token: string) => api.delete(`${endpoints.SERVICE}/Application/${serviceId}/${applicationId}`, { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelTokenSource.token })