import {
    ChakraProvider,
    Box,
    Grid,
    theme,
} from "@chakra-ui/react"
import { BrowserRouter } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { AppRoutes, AuthRoutes } from "./Routes"
import { AuthProvider } from "./Hook/useAuth";

export const App = () => {

    return(
        <ChakraProvider theme={theme}>
            <Box minHeight="100vh" fontFamily="Inter">
                <Grid>
                    <AuthProvider>
                        <BrowserRouter>
                            <AuthenticatedTemplate>
                                <AppRoutes />
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <AuthRoutes />
                            </UnauthenticatedTemplate>
                        </BrowserRouter>
                    </AuthProvider>
                </Grid>
            </Box>
        </ChakraProvider>
    )
}

