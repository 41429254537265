import { 
    Box,
    Button, 
    Drawer, 
    DrawerBody, 
    DrawerCloseButton, 
    DrawerContent, 
    DrawerFooter, 
    DrawerHeader, 
    DrawerOverlay, 
    FormControl, 
    FormLabel, 
    Input, 
    Stack, 
    Text, 
    Image,
    useToast, 
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FiSave, FiTrash2 } from 'react-icons/fi'
import Select from 'react-select'

import { 
    updateService,
    postService,
    getComponents,
    deleteService,
    getServiceById
} from "../../../Api/IncidentManagement";
import { IFormProps, ICommonProps } from "./../Common/interfaces";
import { useAuth } from "../../../Hook/useAuth";
import { ICreateService } from "./interface";


export function ServiceForm({ isOpen, setIsOpen, handleReloadTable, id }: IFormProps) {
    const [loading, setLoading] = useState(false)
    const [showLogoPreview, setShowLogoPreview] = useState(false)
    const [iconUrl, setIconUrl] = useState('')
    const toast = useToast()   
    const { accessToken } = useAuth() 
    const [component, setComponent] = useState([])

    const formik = useFormik({
        initialValues: {
            name: '',
            componentId: 0,
            file: []
        },
        validate: values => {
            let errors = {} as any
            if(!values.name) {
                errors.name = "Este campo é obrigatório"
            }

            if(!values.componentId) {
                errors.locationId = "Este campo é obrigatório"
            }

            /*if(!values.file || values.file.length === 0) {
                errors.file = "Este campo é obrigatório"
            }*/

            return errors;
        },
        onSubmit: async (values: ICreateService, { setSubmitting }) => {
            debugger;
            if(loading) {
                return
            }

            let payload = {} as ICreateService
            Object.entries(values).forEach(([key, value]) => {
                if(value) {
                    payload = {
                        ...payload,
                        [key]: value
                    }
                }
            })

            let data = new FormData();
            data.append("name", payload.name);
            data.append("componentId", payload.componentId.toString());
            data.append("logo", (values.file[0] || null));

            try {
                let response = null
                if(id) {
                    response = await updateService({id: id, data: data}, accessToken)
                } else {
                    response = await postService(data, accessToken)
                }
                
                if(response.status === 200) {
                    formik.resetForm()
                    handleReloadTable()

                    toast({
                        title: 'Sistema',
                        description: "Operação bem sucedida!",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Sistema',
                        description: "Ocorreu um erro no registo de sistema!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Sistema',
                    description: "Ocorreu um erro no registo de sistema!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            } finally {
                setSubmitting(false)
            }            
        },
    })

    useEffect(() => {

        const loadComponents = async(token: string) => {
            try {
                const components = await getComponents(token)

                if(components.status === 200) {
                    const componentDropDownOptions= components.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                    setComponent(componentDropDownOptions)
                }

                if(components.status !== 200) {
                    toast({
                        title: 'Componente',
                        description: "Ocorreu ao ler dados!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Componente',
                    description: "Ocorreu ao ler dados!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }

        if(accessToken) {
            loadComponents(accessToken)
        }
        // eslint-disable-next-line
    }, [accessToken])

    const handleDeleteService = async(id: number, token: string) => {
        try {
            setLoading(true)
            const response = await deleteService(id, token)

            if(response.status === 200) {
                formik.resetForm()                
                handleReloadTable()

                toast({
                    title: 'Sistema',
                    description: "O registo da componente foi eliminado com sistema!",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: 'Sistema',
                    description: "Ocorreu erro ao eliminar sistema!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Sistema',
                description: "Ocorreu erro ao eliminar sistema!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setLoading(false)
        }
    }

    const loadServiceById = async(id: number, token: string) => {
        try {
            setLoading(true);
            const response = await getServiceById(id, token)
            if(response.status === 200) {

                const {
                    name,
                    component,
                    logoUrl
                } = response.data

                if(logoUrl && logoUrl !== ''){
                    setShowLogoPreview(true);
                    setIconUrl(logoUrl);
                }

                await formik.setFieldValue("name", name)
                await formik.setFieldValue("componentId", component.id)
            } else {
                toast({
                    title: 'Sistema',
                    description: "Ocorreu erro ao carregar sistema!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Sistema',
                description: "Ocorreu erro ao carregar sistema!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        if(id && accessToken) {
            loadServiceById(id, accessToken)
        } else if(!id) {
            setShowLogoPreview(false);
        }

        // eslint-disable-next-line
    }, [id, accessToken])

    return(
        <Drawer
            size="xl"
            isOpen={isOpen}
            placement='right'
            onClose={() => {
                if(loading) {
                    return
                }
                formik.resetForm()
                setIsOpen(!isOpen)
                setShowLogoPreview(false);
                setIconUrl('');
            }}>
            <DrawerOverlay />
            <DrawerContent overflowY="scroll">
                <DrawerCloseButton />

                <DrawerHeader borderBottomWidth='1px'>
                    <Text>Sistemas</Text>
                </DrawerHeader>

                <form onSubmit={formik.handleSubmit}>
                    <DrawerBody overflow="visible">
                        <Stack spacing="4" p="8">
                            <FormControl isRequired>
                                <FormLabel htmlFor="name">Nome</FormLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    maxLength={1000} 
                                    placeholder='Nome' 
                                    onChange={formik.handleChange}
                                    value={formik.values.name}                              
                                />
                                {formik.errors.name && !formik.values.name ? <Text color="red" fontSize='xs'>{formik.errors.name}</Text> : null}
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel htmlFor="componentId">Componente</FormLabel>
                                <Select
                                    id="componentId"
                                    name="componentId"
                                    placeholder="Componente"
                                    options={component} 
                                    onChange={({ value }: any) => {
                                        formik.setFieldValue('componentId', value)
                                    }}
                                    value={component.find((row: any) => row?.value === formik.values.componentId)}
                                />
                                {formik.errors.componentId && !formik.values.componentId ? <Text color="red" fontSize='xs'>{formik.errors.componentId}</Text> : null}
                            </FormControl>
                            <FormControl isRequired={id ? false : true}>
                                <FormLabel htmlFor="file">Logo <span style={{fontSize: "10px"}}>(Apenas são permitidos ficheiros gif, png, jpeg, jpg)</span></FormLabel>
                                <Input
                                    id="file"
                                    name="file"
                                    placeholder="Logo"
                                    accept=".gif,.png,.jpeg,.jpeg2000,.jpg"
                                    onChange={(event) => {
                                        setShowLogoPreview(false);
                                        const file = event.target.files;
                                        formik.setFieldValue("file", file);
                                      }}
                                    type="file"
                                />
                                {formik.errors.file && !formik.values.file ? <Text color="red" fontSize='xs'>{formik.errors.file}</Text> : null}

                            </FormControl>
                            {showLogoPreview && iconUrl !== "" ? 
                                <Box boxSize="12">
                                    <Image borderRadius="full" src={iconUrl} alt="Logo" />
                                </Box>
                              : null
                            }
                        </Stack>
                    
                    </DrawerBody>

                    <DrawerFooter justifyContent="start" px="14">
                        {id ?
                            <Button isLoading={loading} colorScheme="red" leftIcon={<FiTrash2 />} variant='ghost' mr="4" onClick={() => handleDeleteService(id, accessToken)}>
                                Remover sistema
                            </Button>
                        : null }
                        <Button isLoading={formik.isSubmitting} type="submit" colorScheme='blue' leftIcon={<FiSave />}>Guardar</Button>                        
                    </DrawerFooter>
                </form>
            </DrawerContent>
        </Drawer>
    )
}