import { useMemo } from 'react';
import { IUsePaginationProps } from './interface';

export const DOTS = '...'

const range = (start: number, end: number) => {
    let length = end - start + 1

    return Array.from({ length }, (_, index) => index + start)
}

export const usePagination = ({
    currentPage, 
    pageSize,
    totalCount,
    siblingCount = 1,
    totalPageCount
}:IUsePaginationProps) => {

    const paginationRange = useMemo(() => {

        const totalPageNumbers = siblingCount + 5

        // CASE 1: IF THE NUMBER OF PAGE IS LESS THAN THE PAGE NUMBERS WE WANT TO SHOW
        if(totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount)
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1) 
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        
        // CASE 2: SHOW ONLY RIGHTS DOTS
        if(!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount
            let leftRange = range(1, leftItemCount)

            return [...leftRange, DOTS, totalPageCount]
        }


        // CASE 3: SHOW ONLY LEFT DOTS
        if(shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            )

            return [firstPageIndex, DOTS, ...rightRange]
        }


        // CASE 4: BOTH LEFT AND RIGHT DOTS TO BE SHOWN
        if(shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex)

            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }

        // eslint-disable-next-line
    }, [totalCount, pageSize, siblingCount, currentPage])

    return paginationRange;
}