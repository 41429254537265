import {MENU_ITEMS} from '../../Api/Constants';
import {MobileProps, LinkItemProps, NavItemProps, SidebarProps, IMenuProps} from './interface'
import React from 'react';
import {Link} from "react-router-dom"
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
 // Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
} from '@chakra-ui/react';

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
} from 'react-icons/fi';
import { IconType } from 'react-icons';

export function Menu({activeKey}: IMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        activeKey={activeKey}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} activeKey={activeKey}/>
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
    </Box>
  );
}

const SidebarContent = ({ onClose, activeKey, ...rest }: SidebarProps) => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      border="1px"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      borderTopLeftRadius="md"
      borderBottomLeftRadius="md"
      w={{ base: 'full', md: 60 }}
      h="80vh"
      {...rest}>
      {MENU_ITEMS.map((link) => (
        <NavItem key={link.key} item={link} activeKey={activeKey}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};


const NavItem = ({ item, activeKey, ...rest}: NavItemProps) => {
  return (
    <Link to={item.url}>
      <Flex
        borderLeft={item.key === activeKey ? '3px solid #3072C0' : ''}
        color= {item.key === activeKey ? '#3072C0' : ''}
        align="center"
        p="4"
        role="group"
        cursor="pointer"
        _hover={{
          borderLeft: '3px solid #3072C0',
          color: '#3072C0'
        }}
        {...rest}>
        {item.name}
      </Flex>
    </Link>
  );
};


const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};
