import { 
    Button, 
    Drawer, 
    DrawerBody, 
    DrawerCloseButton, 
    DrawerContent, 
    DrawerFooter, 
    DrawerHeader, 
    DrawerOverlay, 
    FormControl, 
    FormLabel, 
    Input, 
    Stack, 
    Text, 
    useToast, 
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FiSave, FiTrash2 } from 'react-icons/fi'
import Select from 'react-select'

import {
    postComponent,
    updateComponent, 
    getLocations,
    deleteComponent,
    getComponentById
} from "../../../Api/IncidentManagement";
import { IFormProps, ICommonProps } from "./../Common/interfaces";
import { useAuth } from "../../../Hook/useAuth";
import { ICreateComponent } from "./interface";


export function ComponentForm({ isOpen, setIsOpen, handleReloadTable, id }: IFormProps) {
    const [loading, setLoading] = useState(false)
    const toast = useToast()   
    const { accessToken } = useAuth() 
    const [location, setLocation] = useState([])

    const formik = useFormik({
        initialValues: {
            name: '',
            locationId: 0
        },
        validate: values => {
            let errors = {} as any

            if(!values.name) {
                errors.name = "Este campo é obrigatório"
            }

            if(!values.locationId) {
                errors.locationId = "Este campo é obrigatório"
            }

            return errors;
        },
        onSubmit: async (values: ICreateComponent, { setSubmitting }) => {

            if(loading) {
                return
            }

            let payload = {} as ICreateComponent
            Object.entries(values).forEach(([key, value]) => {
                if(value) {
                    payload = {
                        ...payload,
                        [key]: value
                    }
                }
            })

            try {
                let response = null
                if(id) {
                    response = await updateComponent({id: id, data: payload}, accessToken)
                } else {
                    response = await postComponent(payload, accessToken)
                }
                
                if(response.status === 200) {
                    formik.resetForm()
                    handleReloadTable()

                    toast({
                        title: 'Componente',
                        description: "Operação bem sucedida!",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Componente',
                        description: "Ocorreu um erro no registo de componente!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Componente',
                    description: "Ocorreu um erro no registo de componente!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            } finally {
                setSubmitting(false)
            }            
        },
    })

    useEffect(() => {

        const loadLocations = async(token: string) => {
            try {
                const locations = await getLocations(token)

                if(locations.status === 200) {
                    const locationDropDownOptions= locations.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                    setLocation(locationDropDownOptions)
                }

                if(locations.status !== 200) {
                    toast({
                        title: 'Localização',
                        description: "Ocorreu ao ler dados!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Localização',
                    description: "Ocorreu ao ler dados!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }

        if(accessToken) {
            loadLocations(accessToken)
        }
        // eslint-disable-next-line
    }, [accessToken])

    const handleDeleteComponent = async(id: number, token: string) => {
        try {
            setLoading(true)
            const response = await deleteComponent(id, token)

            if(response.status === 200) {
                formik.resetForm()                
                handleReloadTable()

                toast({
                    title: 'Componente',
                    description: "O registo da componente foi eliminado com sucesso!",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: 'Componente',
                    description: "Ocorreu erro ao eliminar componente!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Componente',
                description: "Ocorreu erro ao eliminar componente!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setLoading(false)
        }
    }

    const loadComponentById = async(id: number, token: string) => {
        try {
            const response = await getComponentById(id, token)

            if(response.status === 200) {

                const {
                    name,
                    location
                } = response.data


                await formik.setFieldValue("name", name)
                await formik.setFieldValue("locationId", location.id)
            } else {
                toast({
                    title: 'Componente',
                    description: "Ocorreu erro ao carregar componente!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Componente',
                description: "Ocorreu erro ao carregar componente!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    useEffect(() => {
        if(id && accessToken) {
            loadComponentById(id, accessToken)
        }

        // eslint-disable-next-line
    }, [id, accessToken])



    return(
        <Drawer
            size="xl"
            isOpen={isOpen}
            placement='right'
            onClose={() => {
                if(loading) {
                    return
                }
                formik.resetForm()
                setIsOpen(!isOpen)
            }}            
        >
            <DrawerOverlay />
            <DrawerContent overflowY="scroll">
                <DrawerCloseButton />

                <DrawerHeader borderBottomWidth='1px'>
                    <Text>Componente</Text>
                </DrawerHeader>

                <form onSubmit={formik.handleSubmit}>
                    <DrawerBody overflow="visible">
                        <Stack spacing="4" p="8">
                            <FormControl isRequired>
                                <FormLabel htmlFor="name">Nome</FormLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    maxLength={1000} 
                                    placeholder='Nome' 
                                    onChange={formik.handleChange}
                                    value={formik.values.name}                              
                                />
                                {formik.errors.name && !formik.values.name ? <Text color="red" fontSize='xs'>{formik.errors.name}</Text> : null}
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel htmlFor="locationId">Localização</FormLabel>
                                <Select
                                    id="locationId"
                                    name="locationId"
                                    placeholder="Localização"
                                    options={location} 
                                    onChange={({ value }: any) => {
                                        formik.setFieldValue('locationId', value)
                                    }}
                                    value={location.find((row: any) => row?.value === formik.values.locationId)}
                                />
                                {formik.errors.locationId && !formik.values.locationId ? <Text color="red" fontSize='xs'>{formik.errors.locationId}</Text> : null}
                            </FormControl>
                        </Stack>
                    
                    </DrawerBody>

                    <DrawerFooter justifyContent="start" px="14">
                        {id ?
                            <Button isLoading={loading} colorScheme="red" leftIcon={<FiTrash2 />} variant='ghost' mr="4" onClick={() => handleDeleteComponent(id, accessToken)}>
                                Remover componente
                            </Button>
                        : null }
                        <Button isLoading={formik.isSubmitting} type="submit" colorScheme='blue' leftIcon={<FiSave />}>Guardar</Button>                        
                    </DrawerFooter>
                </form>
            </DrawerContent>
        </Drawer>
    )
}