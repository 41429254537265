import { 
    Flex, 
    Text, 
    HStack, 
    Image, 
    StackDivider, 
    Button, 
    IconButton
} from "@chakra-ui/react"
import { FiArrowLeft, FiLogOut } from "react-icons/fi"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { MdAddCircleOutline, MdSettings } from "react-icons/md"

import Logo from "../../Assets/Img/AMORIM.png";
import { INavigationProps } from "./interface";
import { useAuth } from "../../Hook/useAuth";
import { MENU_ITEMS } from "../../Api/Constants";

export function Navigation({ handleAddAction, addButtonText, showSettingsButton }: INavigationProps) {
    const navigate = useNavigate()
    const location = useLocation()
    const { handleLogout, userRoles } = useAuth()

    return(
        <Flex mb="8" justify="space-between">
            <HStack divider={<StackDivider />} spacing={4} >
                <Link to="/">
                    <Image src={Logo} alt='AMORIM' />
                </Link>                
                <Text fontWeight="800" fontSize="30">Service Down</Text>
            </HStack >
            <HStack spacing={4}>

                {location.pathname !== "/" && (
                    <Button onClick={() => navigate("/")} leftIcon={<FiArrowLeft />} variant="outline">
                        Voltar
                    </Button>
                )}

                {
                    userRoles?.includes(process.env.REACT_APP_USER_ROLE_ADMIN as string) || 
                    userRoles?.includes(process.env.REACT_APP_USER_ROLE_INCIDENT_MANAGER as string) ?
                    (
                        <>
                            {location.pathname === '/' && (
                                <Button colorScheme='blue'>
                                    <Link to="/manage/incident">
                                        Gestão de Incidentes
                                    </Link>
                                </Button>
                            )}                    

                            {location.pathname.startsWith('/manage/') && (
                                <Button onClick={handleAddAction} leftIcon={<MdAddCircleOutline />} colorScheme='blue'>
                                    {addButtonText || 'Adicionar novo item'}
                                </Button>
                            )}
                        </>
                    ) : null
                }

                {userRoles?.includes(process.env.REACT_APP_USER_ROLE_ADMIN as string) && showSettingsButton === true && (
                    
                        <Link to={MENU_ITEMS[0].url}>
                            <Button colorScheme='blue'>
                                <MdSettings />
                            </Button>
                        </Link>
                    
                )}

                <IconButton onClick={handleLogout} icon={<FiLogOut />} aria-label='Search database' variant="outline" />
                {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
            </HStack >
        </Flex>
    )
}