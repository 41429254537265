import { LinkItemProps } from "../../Components/Menu/interface";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const endpoints = {
    INCIDENT: "Incident",
    INCIDENT_IMPACT_LEVEL: "IncidentImpactLevel",
    INCIDENT_STATUS: "IncidentStatus",

    LOCATION: "Location",

    COMPANY: "Company",

    COMPONENT: "Component",
    COMPONENT_BY_LOCATION: "Component/GetByLocation",

    SERVICE: "Service",
    SERVICE_BY_COMPONENT: "Service/GetByComponent",

    APPLICATION: "Application",
    APPLICATION_BY_SERVICE: "Application/GetByService",
    APPLICATION_AFFECTED: "Application/GetAfectedApplications",
    MY_APPLICATIONS_AFFECTED: "Application/GetMyAfectedApplications"
}

export const MENU_ITEMS: Array<LinkItemProps> = [
    { name: 'Unidade Industrial', url: '/manage/companies', key: 'companies' },
    { name: 'Localizações', url: '/manage/locations', key: 'locations' },
    { name: 'Perímetros de Impacto', url: '/manage/components', key: 'components' },
    { name: 'Objetos Aplicacionais', url: '/manage/services', key: 'services' },
    { name: 'Produtos', url: '/manage/applications', key: 'applications' },
    { name: 'Relação Produto / Objetos Aplicacionais', url: '/manage/applicationServices', key: 'applicationServices' },
];

export const MENU_KEYS = {
    CompanyMenuKey: 'companies',
    LocationMenuKey: 'locations',
    ComponentMenuKey: 'components',
    ServiceMenuKey: 'services',
    ApplicationMenuKey: 'applications',
    ApplicationServiceMenuKey: 'applicationServices',
}