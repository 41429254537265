import { 
    Button, 
    Drawer, 
    DrawerBody, 
    DrawerCloseButton, 
    DrawerContent, 
    DrawerFooter, 
    DrawerHeader, 
    DrawerOverlay, 
    FormControl, 
    FormLabel, 
    Stack, 
    Text, 
    useToast, 
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FiSave, FiTrash2 } from 'react-icons/fi'
import Select from 'react-select'

import { 
    postServiceApplication,
    getServices,
    getApplications,
    deleteServiceApplication
} from "../../../Api/IncidentManagement";
import {ICommonProps } from "./../Common/interfaces";
import { useAuth } from "../../../Hook/useAuth";
import { ICreateServiceApplication, IFormProps } from "./interface";


export function ServiceApplicationForm({ isOpen, setIsOpen, handleReloadTable, serviceId, applicationId }: IFormProps) {
    const [loading, setLoading] = useState(false)
    const toast = useToast()   
    const { accessToken } = useAuth() 
    const [service, setService] = useState([])
    const [application, setApplication] = useState([])

    const formik = useFormik({
        initialValues: {
            serviceId: 0,
            applicationId: 0
        },
        validate: values => {
            let errors = {} as any
            if(!values.serviceId) {
                errors.serviceId = "Este campo é obrigatório"
            }

            if(!values.applicationId) {
                errors.applicationId = "Este campo é obrigatório"
            }

            return errors;
        },
        onSubmit: async (values: ICreateServiceApplication, { setSubmitting }) => {
            if(loading) {
                return
            }

            let payload = {} as ICreateServiceApplication
            Object.entries(values).forEach(([key, value]) => {
                if(value) {
                    payload = {
                        ...payload,
                        [key]: value
                    }
                }
            })

            try {
                let response = null
                if(serviceId && applicationId) {
                    //response = await updateService({id: id, data: data}, accessToken)
                } else {
                    response = await postServiceApplication(payload, accessToken)
                }
                
                if(response && response.status === 200) {
                    formik.resetForm()
                    handleReloadTable()

                    toast({
                        title: 'Aplicações e Sistemas',
                        description: "Operação bem sucedida!",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Aplicações e Sistemas',
                        description: "Ocorreu um erro no registo de aplicações e sistemas!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Aplicações e Sistemas',
                    description: "Ocorreu um erro no registo de aplicações e sistemas!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            } finally {
                setSubmitting(false)
            }            
        },
    })

    useEffect(() => {

        const loadServiceAndApplications = async(token: string) => {
            try {
                const services = await getServices(token);
                const applications = await getApplications(token);

                if(services.status === 200) {
                    const serviceDropDownOptions= services.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                    setService(serviceDropDownOptions)
                }

                if(applications.status === 200) {
                    const applicationDropDownOptions= applications.data.map((row: ICommonProps) => ({ value: row.id, label: row.name}))
                    setApplication(applicationDropDownOptions)
                }

                if(services.status !== 200 || applications.status !== 200) {
                    toast({
                        title: 'Aplicações e Sistemas',
                        description: "Ocorreu ao ler dados!",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                toast({
                    title: 'Aplicações e Sistemas',
                    description: "Ocorreu ao ler dados!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }

        if(accessToken) {
            loadServiceAndApplications(accessToken)
        }
        // eslint-disable-next-line
    }, [accessToken])

    const handleDeleteServiceApplication = async(serviceId: number, applicationId: number, token: string) => {
        try {
            setLoading(true)
            const response = await deleteServiceApplication(serviceId, applicationId, token)

            if(response.status === 200) {
                formik.resetForm()                
                handleReloadTable()

                toast({
                    title: 'Aplicações e Sistemas',
                    description: "O registo da componente foi eliminado com aplicações e sistemas!",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: 'Aplicações e Sistemas',
                    description: "Ocorreu erro ao eliminar aplicações e sistemas!",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            toast({
                title: 'Aplicações e Sistemas',
                description: "Ocorreu erro ao eliminar aplicações e sistemas!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setLoading(false)
        }
    }

    const loadServiceApplicationById = async(serviceId: number, applicationId: number, token: string) => {
        try {
            setLoading(true);
            await formik.setFieldValue("serviceId", serviceId)
            await formik.setFieldValue("applicationId", applicationId);

        } catch (error) {
            toast({
                title: 'Sistema',
                description: "Ocorreu erro ao carregar sistema!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        if(serviceId && applicationId && accessToken) {
            loadServiceApplicationById(serviceId, applicationId, accessToken)
        }

        // eslint-disable-next-line
    }, [serviceId, applicationId, accessToken])

    return(
        <Drawer
            size="xl"
            isOpen={isOpen}
            placement='right'
            onClose={() => {
                if(loading) {
                    return
                }
                formik.resetForm()
                setIsOpen(!isOpen)
            }}>
            <DrawerOverlay />
            <DrawerContent overflowY="scroll">
                <DrawerCloseButton />

                <DrawerHeader borderBottomWidth='1px'>
                    <Text>Sistemas</Text>
                </DrawerHeader>

                <form onSubmit={formik.handleSubmit}>
                    <DrawerBody overflow="visible">
                        <Stack spacing="4" p="8">
                        <FormControl isRequired>
                                <FormLabel htmlFor="applicationId">Aplicação</FormLabel>
                                <Select isDisabled={serviceId && applicationId ? true : false}
                                    id="applicationId"
                                    name="applicationId"
                                    placeholder="Aplicação"
                                    options={application} 
                                    onChange={({ value }: any) => {
                                        formik.setFieldValue('applicationId', value)
                                    }}
                                    value={application.find((row: any) => row?.value === formik.values.applicationId)}
                                />
                                {formik.errors.applicationId && !formik.values.applicationId ? <Text color="red" fontSize='xs'>{formik.errors.applicationId}</Text> : null}
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel htmlFor="serviceId">Produto</FormLabel>
                                <Select isDisabled={serviceId && applicationId ? true : false}
                                    id="serviceId"
                                    name="serviceId"
                                    placeholder="Serviço"
                                    options={service} 
                                    onChange={({ value }: any) => {
                                        formik.setFieldValue('serviceId', value)
                                    }}
                                    value={service.find((row: any) => row?.value === formik.values.serviceId)}
                                />
                                {formik.errors.serviceId && !formik.values.serviceId ? <Text color="red" fontSize='xs'>{formik.errors.serviceId}</Text> : null}
                            </FormControl>
                        </Stack>
                    
                    </DrawerBody>

                    <DrawerFooter justifyContent="start" px="14">
                        {serviceId && application ?
                            <Button isLoading={loading} colorScheme="red" leftIcon={<FiTrash2 />} variant='ghost' mr="4" onClick={() => handleDeleteServiceApplication(serviceId, applicationId, accessToken)}>
                                Remover sistema
                            </Button>
                        : null }
                        {serviceId && application ? null : 
                            <Button isLoading={formik.isSubmitting} type="submit" colorScheme='blue' leftIcon={<FiSave />}>Guardar</Button>  
                        }                      
                    </DrawerFooter>
                </form>
            </DrawerContent>
        </Drawer>
    )
}